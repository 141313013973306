export default {
	backendEndpoint: `${process.env.REACT_APP_BASE_URL}/api/v1` || 'http://localhost:5000/api/v1',
	// "http://localhost:5000/api/v1",
	// `${process.env.REACT_APP_BASE_URL}/api/v1` || 'http://localhost:5000/api/v1',

	taskNewVersionPath: "/task-new-version",
	taskDetailPath: "/task-detail",
	taskNewVersionSubPath: {
		mobileAppList: "/mobile-app-list",
		createTaskFromApp: "/create-task-from-app",
		addComments: "/add-comments",
		commentsListing: "/comments-listing",
		checkListItemStatusChange: "/check-list-item-status-change",
		taskStatusComplete: "/task-status-complete",
		byID: "/:id",
		findByID: "/find-one/:id",
		simpleListing: "/simple-listing"
	},
	commonRoute: {
		root: "/common-uri",
		getPreSignedURL: "/get-pre-signed-url",
		formDataFileUpload: "/form-data-file-upload",
		fileUploadS3: "/file-upload-s3",
		knowMyAccess: "/know-my-access"
	},
	siteRoute: {
		root: "/site-panel",
		webAppList: "/web-app-list",
		createNewOne: "/create-new-one",
		updateOne: "/update-one",
		deleteOne: "/delete-one",
		simpleList: "/simple-list",
		findOne: "/find-one/:id",
		timeslot: '/open-time-slot/:id'

	},
	staffRoute: {
		root: '/staff-people',
		webAppList: '/web-app-list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		simpleList: '/simple-list',
		findOne: '/find-one/:id',
		staffLogin: '/login',
		updateStatus: '/update-status',
		downloadStaffData: '/download',
		uploadStaff: '/upload',
		resendInvite: '/resendInvite',
		getTasksForAssign: '/get-tasks-for-assign',
		bulkAssign: '/bulk-assign',
		simpleList2: '/simple-list2'
	},
	departmentRoute: {
		root: '/department-panel',
		webAppList: '/web-app-list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		simpleList: '/simple-list',
		simpleList2: '/simple-list2',
		findOne: '/find-one/:id'
	},


	storageTokenKeyName: "accessToken",
	orgTokenName: "orgId",
	isAdminToken: "isAdminToken",
	onTokenExpiration: "logout", // logout | refreshToken

	methodType: {
		get: "GET",
		post: "POST",
		put: "PUT",
		patch: "PATCH",
		delete: "DELETE"
	},

	defaultListingParameter: {
		search: '',
		sortColumn: '_id',
		sort: 'asc',
		limit: 10,
		page: 1
	},

	defaultListingRole: {
		role: 'SITE_ADMIN'
	},

	contentTypeEnum: {
		applicationJSON: 'application/json',
		multipartFormData: 'multipart/form-data'
	},

	organizationRoute: {
		root: '/organization',
		list: '/list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one',
		viewOne: '/view-one',
		updateNote: '/update-note'

	},
	userProfileRoute: {
		root: '/user-profile',
		viewMe: '/view-me',
		updateMe: '/update-me',
		changePassword: '/change-password',
		changeNotificationSettings: '/change-notification-settings',
		deleteMyAccount: '/delete-my-account'
	},
	templatesRoute: {
		root: '/template-panel',
		webAppList: '/web-app-list',
		SimpleList: '/simple-list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		viewOne: '/view-one',
		updateNote: '/update-note',
		findOne: '/find-one/:id'
	},
	taskCategoriesRoute: {
		root: '/task-category',
		webAppList: '/web-app-list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		simpleList: '/simple-list',
		simpleList2: '/simple-list2',
		findOne: '/find-one/:id'
	},
	preDefinedTemplate: {
		root: '/pre-defined-template',
		simpleList: '/simple-list',
		findOne: '/find-one/:id',
		importOne: '/import-one'
	},
	authenticationRoute: {
		root: '/authentication',
		webAppLogin: '/web-app-login',
		mobileAppLogin: '/mobile-app-login',
		forgetPassword: '/forget-password',
		resetPassword: '/reset-password',
		logout: '/logout',
		adminUserData: '/getAdminUserById'
	},
	taskRoute: {
		root: '/master-task',
		createNewOne: '/create-new-one',
		simpleList: '/simple-list',
		deleteOne: '/delete-one/:id',
		findOne: '/find-one/:id',
		updateOne: '/update-one',
		webAppList: '/web-app-list',
		viewTask: '/view-task'
	},
	commentTask: {
		root: '/comment-task',
		addComments: '/create-new-one',
		commentList: '/comments-listing'

	},
	individualTask: {
		root: '/individual-task',
		checkListStatus: '/check-list-item-status-change',
		taskStatus: '/task-status-complete'
	},
	adminData: {
		root: '/admin-panel/authentication',
		adminUserData: '/getAdminUserById'
	},
	supplierRoute: {
		root: '/supplier',
		webAppList: '/web-app-list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		simpleList: '/simple-list',
		findOne: '/find-one/:id'
	},
	foodItemRoute: {
		root: '/foodItem',
		webAppList: '/web-app-list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		simpleList: '/simple-list',
		findOne: '/find-one/:id'
	},
	probRoute: {
		root: '/foodItem',
		webAppList: '/web-app-list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		simpleList: '/simple-list',
		findOne: '/find-one/:id'
	},
	masterTypeRoute: {
		root: '/masterType',
		webAppList: '/web-app-list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		simpleList: '/simple-list',
		findOne: '/find-one/:id',
		findMasterTypeBySiteID: '/find-by-siteID/:siteID'
	},
	freezerRoute: {
		root: '/masterType',
		webAppList: '/web-app-list',
		createNewOne: '/create-new-one',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		simpleList: '/simple-list',
		findOne: '/find-one/:id',
		findMasterTypeBySiteID: '/find-by-siteID/:siteID'
	},
	locationRoute: {
		root: '/location',
		simpleListCountries: '/simple-list-countries',
		webAppList: '/web-app-list'

	},
	temperatureLogRoute: {
		root: '/temperature-logs',
		webAppList: '/web-app-list',
		updateOne: '/update-one',
		deleteOne: '/delete-one/:id',
		logHistoryList: '/log-history-list',
		findOne: '/find-one/:id',
		webAppListForWeb: '/web-app-list-for-web',
		exportWebAppListForWeb: '/export-web-app-list-for-web'
	},
	complianceLogRoute: {
		root: '/comlianceLog',
		webAppList: '/web-app-list',
		webAppListForWeb: '/web-app-list-for-web',
		findOne: '/find-one/:id',
		updateOne: '/update-one'
	},
	documentMangementRoute: {
		root: '/document',
		createNewOne: '/create-new-one',
		webAppList: '/web-app-list',
		findOne: '/find-one/:id',
		updateOne: '/update-one'
		// webAppListForWeb: '/web-app-list-for-web',
	}

}